import React from "react";
import './App.css';
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

const base = "https://photo.kurokawakuniji.com/kuniji%2F"
const imageUrls = `
S__27672578.jpg
S__70934556.jpg
S__70934557.jpg
S__70934558.jpg
S__70934559.jpg
S__70934560.jpg
S__70934561.jpg
S__70934562.jpg
S__70934563.jpg
S__70934564.jpg
S__70934565.jpg
S__70934566.jpg
S__70934567.jpg
S__70934568.jpg
S__70934569.jpg
S__70934570.jpg
S__70934571.jpg
S__70934572.jpg
S__70934573.jpg
S__70934574.jpg
S__70934575.jpg
S__70934576.jpg
S__70934577.jpg
S__70934578.jpg
S__70934579.jpg
S__70934580.jpg
S__70934581.jpg
S__70934582.jpg`.split('\n').filter((x) => x.length > 0)

class Gallery extends React.Component {
  render() {
    const images = imageUrls.map((key) => ({
      original: `${base}${key}`,
    }));

    return <ImageGallery items={images} />;
  }
}

function App() {
  return (
    <div className="App">
      <h1>黒川國司ギャラリー</h1>
      <Gallery />
    </div>
  );
}

export default App;
